import React, {Fragment, useState} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import {usePageContext} from '@components/Page';
import {styled, ThemeProvider, theme, Colors} from '@styles';
import {ResponsiveContainer, FontScale} from '@components/layout';
import LocationAddressGrid from '@components/LocationAddressGrid';
import {ContactCallout} from '@components/SectionCallout';
import Helmet from 'react-helmet';
import {ContactForm, ThankYou} from '@components/Form';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import JsonLd from '@components/JsonLd';
import {FormContext} from '../util/formContext'

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  body: Block[];
}

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'contactContainer'
})`
  max-width: 100%;
  padding: 2em;
  margin: 8em auto 0;

  &.wide {
    padding: 0;
    max-width: 80%;
  }
`;

const Divider = styled.div.withConfig({
  componentId: 'contactDivider'
})`
  width: 100%;
  border: 1px solid ${Colors.FogMid};
  margin: 0 auto;
  padding: 0;
`;

const Contact = ({data}: {data: Data}): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  const {seoTitle, searchDescription, body} = data.cms.page;
  const {contact, thankYou} = getDataFromBlockTypes(body);
  const [showThankYou, setShowThankYou] = useState(false);
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <Helmet>
        <meta httpEquiv="Content-type" content="text/html; charset=UTF-8" />
      </Helmet>
      <JsonLd
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Corporation',
          name: '2U, Inc.',
          alternateName: '2U',
          url: 'http://2u.com',
          description: `${searchDescription}`,
          sameAs: [
            'https://www.facebook.com/2u',
            'https://twitter.com/2Uinc',
            'https://www.linkedin.com/company/2u',
            'https://en.wikipedia.org/wiki/2U_(company)',
          ],
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+1-301-892-4350',
            contactType: 'customer support',
            areaServed: 'US',
            availableLanguage: ['English'],
          },
        }}
      />
      <JsonLd
        schema={{
          '@context': 'http://schema.org',
          '@type': 'LocalBusiness',
          name: '2U, Inc.',
          alternateName: '2U',
          url: 'http://2u.com',
          telephone: '+1-301-892-4350',
          location: [
            {
              '@type': 'Place',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '7900 Harkins Road',
                addressLocality: 'Lanham',
                addressRegion: 'MD',
                postalCode: '20706',
              },
              telephone: '+1-301-892-4350',
            },
          ],
        }}
      />
      <Container as={FontScale}>
        <ThemeProvider
          theme={{
            maxWidth: '768px',
            ctaFg: Colors.Blue,
            ctaBg: Colors.Transparent,
            ctaHoverFg: Colors.White,
            ctaHoverBg: Colors.Blue,
            minPadding: '2em',
            maxPadding: '0',
          }}
        >
          <Fragment>
            <FormContext.Provider value={setShowThankYou}>
              {!showThankYou && (
                <Fragment>
                  <ContactCallout {...contact} />
                  <ThemeProvider
                    theme={{ctaFg: Colors.White, ctaBg: Colors.Blue}}
                  >
                    <ContactForm />
                  </ThemeProvider>
                </Fragment>
              )}
            </FormContext.Provider>
            {showThankYou && <ThankYou {...thankYou} />}
          </Fragment>
        </ThemeProvider>
        <Divider />
        <LocationAddressGrid />
      </Container>
    </Fragment>
  );
};

export default Contact;

export const query = graphql`
  fragment ContactFragment on CMS_ContactPage {
    seoTitle
    searchDescription
    body {
      ...sectionBlockFragment
    }
  }
  query Contact {
    cms {
      page(url: "/home/contact/") {
        ...ContactFragment
      }
    }
  }
`;
