import {Color, Size, Space, ThemeGetter} from '@styles/types';

export * from './typography';
export * from './cta';
export * from './quote';
export * from './logo';

/**
 * Use `fg` from the theme.
 */
export const fg: ThemeGetter<Color> = ({theme}): Color => theme.fg;

/**
 * Use `bg` from the theme.
 */
export const bg: ThemeGetter<Color> = ({theme}): Color => theme.bg;

/**
 * Use `maxWidth` from the theme.
 */
export const maxWidth: ThemeGetter<Size> = ({theme}): Size => theme.maxWidth;

/**
 * Use `minWidth` from the theme.
 */
export const minWidth: ThemeGetter<Size> = ({theme}): Size => theme.minWidth;

/**
 * Use `minPadding` from the theme.
 */
export const minPadding: ThemeGetter<Space> = ({theme}): Space =>
  theme.minPadding;

/**
 * Use `maxPadding` from the theme.
 */
export const maxPadding: ThemeGetter<Space> = ({theme}): Space =>
  theme.maxPadding;

/**
 * Use `minHPadding` from the theme.
 * If `minHPadding` is not defined, falls back to `minPadding`.
 */
export const minHPadding: ThemeGetter<Space> = ({theme}): Space =>
  theme.minHPadding || theme.minPadding;

/**
 * Use `maxHPadding` from the theme.
 * If `maxHPadding` is not defined, falls back to `maxPadding`.
 */
export const maxHPadding: ThemeGetter<Space> = ({theme}): Space =>
  theme.maxHPadding || theme.maxPadding;

/**
 * Use `minVPadding` from the theme.
 * If `minVPadding` is not defined, falls back to `minPadding`.
 */
export const minVPadding: ThemeGetter<Space> = ({theme}): Space =>
  theme.minVPadding || theme.minPadding;

/**
 * Use `maxVPadding` from the theme.
 * If `maxVPadding` is not defined, falls back to `maxPadding`.
 */
export const maxVPadding: ThemeGetter<Space> = ({theme}): Space =>
  theme.maxVPadding || theme.maxPadding;
