import {graphql, useStaticQuery} from 'gatsby';
import {Block} from '@util/getDataFromBlockTypes';

export interface Location {
  title: string;
  url: string;
  id: string;
  phone: string;
  address1: string;
  address2: string;
  slug: string;
  abbreviation: string;
  ghid: string[];
  hero: Block[];
}

const LOCATIONS_QUERY = graphql`
  query LOCATIONS_QUERY {
    cms {
      pages(filter: {contentType: "twoupages.locationpage"}) {
        ... on CMS_LocationPage {
          title
          url
          id
          phone
          address1
          address2
          abbreviation
          slug
          ghid
          hero {
            ... on CMS_VideoBlock {
              blockType
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function useLocations(): Location[] | null {
  const data = useStaticQuery(LOCATIONS_QUERY);
  if (!data || !data.cms || !data.cms.pages) return null;
  return data.cms.pages;
}
