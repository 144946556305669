import React, {Fragment} from 'react';
import useHover from '@hzdg/use-hover';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import {styled, theme, Fonts, ThemeProvider, Colors} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {Link, ForwardLink} from '@components/Link';
import useLocations, {Location} from '@util/useLocations';

const Grid = styled.div.withConfig({
  componentId: 'locationAddressGridGrid'
})`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-row-gap: 3em;

  .wide & {
    padding: 3.5em 0 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'locationAddressGridContainer'
})`
  padding: 4em 0 7.5em;
  margin: 0 auto;
`;

const Header = styled.div.withConfig({
  componentId: 'locationAddressGridHeadder'
})`
  display: none;
  .wide & {
    display: block;
    ${Fonts.ProximaNova.variants.ExtraBold};
    font-size: ${33 / 16}em;
    line-height: ${42 / 33};
    letter-spacing: ${-0.86 / 33};
  }
`;

const AddressContainer = styled.div.withConfig({
  componentId: 'locationAddressGridAddressContainer'
})``;

const Label = styled(animated.div).withConfig({
  componentId: 'locationAddressGridAddressContainer'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
`;

const StyledForwardLink = styled(ForwardLink).withConfig({
  componentId: 'locationAddressGridForwardLink'
})`
  margin: 1em 0;
`;

const Copy = styled(animated.div).withConfig({
  componentId: 'locationAddressGridCopy'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${24 / 16}em;
  line-height: ${30 / 24};
  letter-spacing: ${-0.62 / 24};
  text-align: left;
`;

function convertAddressToGoogleLink(address: string): string {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address,
  )}`;
}

const AddressLink = ({address1, address2}: Location): JSX.Element => {
  const [isHover, hoverProps] = useHover();
  const link = convertAddressToGoogleLink(`${address1}, ${address2}`);
  const {color} = useSpring<UseSpringProps>({
    to: {
      color: isHover ? Colors.Blue : Colors.Charcoal,
    },
  });
  return (
    <Link href={link} target="_blank" {...hoverProps}>
      <Copy style={{color}}>
        {address1}
        <br />
        {address2}
      </Copy>
    </Link>
  );
};

const PhoneLink = ({phone = '0000000000'}: Location): JSX.Element => {
  const [isHover, hoverProps] = useHover();
  const link = `tel:${phone.replace(/\D/g, '')}`;
  const {color} = useSpring<UseSpringProps>({
    to: {
      color: isHover ? Colors.Blue : Colors.Charcoal,
    },
  });
  return (
    <Link href={link} target="_blank" {...hoverProps}>
      <Copy style={{color}}>{phone}</Copy>
    </Link>
  );
};

const ArrowLink = ({url, title}: Location): JSX.Element => {
  const [isHover, hoverProps] = useHover();
  const {color} = useSpring<UseSpringProps>({
    to: {
      color: isHover ? Colors.Blue : Colors.Charcoal,
    },
  });
  return (
    <StyledForwardLink
      isHover={isHover}
      {...hoverProps}
      filledOnHover
      href={url}
    >
      <Label style={{color}}>{title}</Label>
    </StyledForwardLink>
  );
};

const LocationAddress = ({locations}: {locations: Location[]}): JSX.Element => {
  return (
    <Fragment>
      {locations.map(
        (location: Location): JSX.Element => (
          <AddressContainer key={location.id}>
            <ThemeProvider
              theme={{
                ctaIconFg: Colors.Blue,
                ctaIconBg: Colors.Blue,
                ctaHoverFg: Colors.White,
                ctaHoverBg: Colors.Blue,
              }}
            >
              <Fragment>
                <ArrowLink {...location} />
                <AddressLink {...location} />
                <PhoneLink {...location} />
              </Fragment>
            </ThemeProvider>
          </AddressContainer>
        ),
      )}
    </Fragment>
  );
};

const LocationAddressGrid = (): JSX.Element => {
  const data = useLocations();
  return (
    <Container>
      <Header>Locations</Header>
      <Grid>{data && <LocationAddress locations={data} />}</Grid>
    </Container>
  );
};

export default LocationAddressGrid;
